@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700&family=Inter:wght@400;500;600;700&display=swap');

:root:root {
  --adm-color-primary: #2B4B8F;
  --adm-button-border-radius: 89px;
  --adm-font-size-main: 14px;
  --adm-font-family: Gilroy, Lato, Inter, -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-center-popup-max-width: 90vw;
  --adm-color-secondary: #E6E9F2;
  font-size: 14px;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.App {
  background: #F1F2F5;
  /* normal desc text */
  color: rgba(92, 98, 106, 0.8);
  line-height: 1.57;
}

.lighter-text {
  color: #bbbbbb;
}

.toast-error-msg {
  .adm-toast-main {
    max-width: 330px !important;
  }
}


/* rewrite styles of antd-mobile */

.adm-popup.adm-action-sheet-popup>.adm-popup-body {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.full-screen-modal-mask+.adm-center-popup-wrap,
.full-screen-modal-mask+.adm-center-popup-wrap {
  width: 90vw;
}

.adm-center-popup-wrap .adm-modal-content,
.adm-center-popup-wrap .adm-modal-footer {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.adm-center-popup-wrap .adm-modal-footer .adm-modal-button {
  font-size: var(--adm-font-size-6);
}

.customize-icon {
  vertical-align: middle;
}

.adm-button-secondary {
  --color: var(--adm-color-secondary) !important;
  --text-color: var(--adm-color-primary) !important;
}

.adm-dropdown-popup .adm-popup-body {
  /* margin: 16px;
  margin-top: 0px;
  width: calc(100vw - 32px);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
  border: 1px solid rgba(147, 147, 147, 0.20);
  overflow: hidden;
}

.adm-dropdown-popup .adm-popup-body .adm-dropdown-item-content div {
  padding: 16px;
  border-bottom: 1px solid rgba(147, 147, 147, 0.20);
  color: #5E687D;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.adm-toast-main {
  border-radius: 8px;
  background: #98A9BC !important;
}

.adm-toast-main .adm-auto-center-content {
  color: #FFF;
  font-size: 10px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.3px;
}

@media screen and (min-width: 481px) {
  body {
    --adm-font-size-main: 16px;
    font-size: 16px;
  }

  :root:root {
    font-size: 16px;
  }
}

@media screen and (min-width: 769px) {
  .App {
    /* max-width: 850px;/ */
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (min-height: 767px) {

  /* ipad view */
  .full-screen-modal-mask+.adm-center-popup-wrap,
  .full-screen-modal-mask+.adm-center-popup-wrap {
    max-width: 35rem;
  }

  .adm-center-popup-wrap .adm-modal-body:not(.adm-modal-with-image) {
    padding-top: 2rem;
  }

  .adm-center-popup-wrap .adm-modal-body:not(.adm-modal-with-image) .adm-modal-content {
    padding: 0 2rem;
  }

  .adm-center-popup-wrap .adm-modal-body:not(.adm-modal-with-image) .adm-modal-footer {
    padding: 2rem;
  }

  .adm-modal-footer .adm-space-item {
    text-align: right;
  }

  .adm-modal-footer .adm-space-item button {
    display: inline-block;
    width: auto;
    min-width: 4.625rem;
  }
}