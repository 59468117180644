.onboarding .payment .middle{
    padding: 1.143rem;
    padding-top: 1.714rem;
    font-weight: 400;
    font-size: 1.286rem;
    line-height: 135%;
    background-color: #F1F2F5;
    padding-bottom: 7rem;
}

.onboarding .payment .middle .tips{
    padding: .857rem;
    background: #E8ECF1;
    color: #52606C;
    display: flex;
    align-items: flex-start;
    font-style: normal;
    font-weight: 700;
    font-size: .857rem;
    line-height: 1.286rem;
}

.onboarding .payment .middle .tips > img{
    margin-right: 1rem;
}

.onboarding .payment .middle > .title{
    font-style: normal;
    font-weight: 700;
    font-size: .857rem;
    line-height: 1rem;
    letter-spacing: 0.3px;
    color: #323F4A;
    margin-top: 1.714rem;
    margin-bottom: .857rem;
}

.onboarding .payment .middle > .payment{
    display: flex;
    flex-direction: column;
}

.onboarding .payment .middle > .payment input{
    background: #FBFBFB;
    border: .071rem solid #E6E9F2;
    border-radius: .571rem;
    padding: 1.143rem;
}

.onboarding .payment .middle > .payment .name{
    margin-bottom: .571rem;
}

.onboarding .payment .middle > .membership {
    background: #E8ECF1;
    border-radius: 4px;
    padding: 1.143rem;
}

.onboarding .payment .middle > .membership .ms-top{
    display: flex;
}

.onboarding .payment .middle > .membership .ms-top > img{
    background-color: red;
    height: 6rem;
    width: 6rem;
    min-width: 6rem;
    margin-right: 1.714rem;
}

.onboarding .payment .middle > .membership .ms-top .oym{
    color: #323F4A;
    font-style: normal;
    font-weight: 500;
    font-size: 1.143rem;
    line-height: 140%;
}

.onboarding .payment .middle > .membership .ms-top .yopt{
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: .021rem;
    color: #5E687D;
}
.onboarding .payment .middle > .membership ul{
    padding: 0px;
    margin: 0px;
}

.onboarding .payment .middle > .membership ul li{
    list-style-type: none;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.357rem;
    letter-spacing: .021rem;
    color: #5E687D;
    display: flex;
    align-items: baseline;
    margin-top: 1.714rem;
}

.onboarding .payment .middle > .membership ul li > img{
    margin-right: .714rem;
}

.onboarding .payment .middle > .note{
    font-style: italic;
    font-weight: 400;
    font-size: .857rem;
    line-height: 1.286rem;
    letter-spacing: 0.3px;
    color: #5E687D;
    margin-top: 1.714rem;
}

.onboarding .payment > .control{
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5.714rem;
    background-color: white;
    padding: 0px 1.714rem;
    justify-content: space-between;
    box-shadow: 0px 4px 30px rgba(0,0,0,0.05);
}

.onboarding .payment > .control .back{
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 2.8px;
    color: #264382;
}

.onboarding .payment > .control .next{
    height: 2.857rem;
}

@media (min-width: 1024px){
    .onboarding .payment > .control {
        left: 27%;
    }
}
